import {Expose} from "class-transformer";

export class Camera {
    @Expose({name:"id"})
    private _id!: number;
    @Expose({name:"name"})
    private _name!: string;
    @Expose({name:"url"})
    private _url!: string;


    constructor(id: number, name: string, url: string) {
        this._id = id;
        this._name = name;
        this._url = url;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        this._url = value;
    }
}