import {Expose, Type} from "class-transformer";
import {SchoolClass} from "@/entity/SchoolClass";
import {ActiveCard} from "@/entity/ActiveCard";
import moment from "moment";
import {Invoice} from "@/entity/Invoice";


export class User {
    @Expose({name : "id"})
    private _id!: number | null;
    @Expose({name : "firstName"})
    private _firstName: string | null;
    @Expose({name : "lastName"})
    private _lastName: string | null;
    @Expose({name : "email"})
    private _email: string | null;
    @Expose({name : "phone"})
    private _phone: string | null;
    @Expose({name : "payStatus"})
    private _payStatus!: number;
    @Expose({name: "schoolId"})
    private _schoolId!: number;
    @Expose({name: "expiredAt"})
    private _expiredAt: any;
    @Expose({name: "countUnTrustedEnters"})
    private _countUnTrustedEnters: number = 0;

    @Expose({name: "schoolClass"})
    @Type(() => SchoolClass)
    private _schoolClass!: SchoolClass;

    @Expose({name: "activeCard"})
    @Type(() => ActiveCard)
    private _activeCard!: ActiveCard;

    @Expose({name: "invoices"})
    @Type(() => Invoice)
    public invoices: Invoice[] = [];


    constructor(firstName: string | null, lastName: string | null, email: string | null, phone: string | null) {
        this._firstName = firstName;
        this._lastName = lastName;
        this._email = email;
        this._phone = phone;
        this.expiredAt = {
            date : new Date().toString()
        }
    }


    get id(): number | null {
        return this._id;
    }

    set id(value: number | null) {
        this._id = value;
    }

    get firstName(): string | null {
        return this._firstName;
    }

    set firstName(value: string | null) {
        this._firstName = value;
    }

    get lastName(): string | null {
        return this._lastName;
    }

    set lastName(value: string | null) {
        this._lastName = value;
    }

    get email(): string | null {
        return this._email;
    }

    set email(value: string | null) {
        this._email = value;
    }

    get phone(): string | null {
        return this._phone;
    }

    set phone(value: string | null) {
        this._phone = value;
    }

    get payStatus(): number {
        return this._payStatus;
    }

    set payStatus(value: number) {
        this._payStatus = value;
    }


    get schoolClass(): SchoolClass {
        return this._schoolClass;
    }

    set schoolClass(value: SchoolClass) {
        this._schoolClass = value;
    }


    get schoolId(): number {
        return this._schoolId;
    }

    set schoolId(value: number) {
        this._schoolId = value;
    }


    get activeCard(): ActiveCard {
        return this._activeCard;
    }

    set activeCard(value: ActiveCard) {
        this._activeCard = value;
    }


    get expiredAt(): any {
        return moment(this._expiredAt.date).format("DD.MM.YYYY");
    }

    set expiredAt(value: any) {
        this._expiredAt = value;
    }


    get countUnTrustedEnters(): number {
        return this._countUnTrustedEnters;
    }

    set countUnTrustedEnters(value: number) {
        this._countUnTrustedEnters = value;
    }
}