import {AuthRequest} from "@/entity/requests/AuthRequest";
import Token from "@/entity/Token";
import {BaseGateway} from "@/gateway/BaseGateway";
import {classToPlain, plainToClass} from "class-transformer";
import {WrongLoginOrPasswordException} from "@/entity/exception/WrongLoginOrPasswordException";
import {NeedMoreInformationException} from "@/entity/exception/NeedMoreInformationException";
import {User} from "@/entity/User";
import {SchoolClass} from "@/entity/SchoolClass";
import {School} from "@/entity/School";

export class AccountGateway extends BaseGateway{

    private mockAccounts = [
        {
            email : "test1@eyeinc.ru",
            password: "898989",
            accessToken: "dd5fd892-ab07-4172-baf4-996f4266354a",
            payStatus: 1

        },
        {
            email: "test2@eyeinc.ru",
            password: "898989",
            accessToken: "dd5fd892-ab07-3893-baf4-996f4266354a",
            payStatus: 0
        }
    ];

    public mockAuth(email: string, password: string){
        const res = this.mockAccounts.filter(account => account.email === email.toLowerCase() && account.password === password);
        return (res.length>0) ? res[0] : null;
    }

    public async auth(authRequest: AuthRequest): Promise<Token>{
        const data = classToPlain(authRequest);
        const res = await this.httpClient.post("/auth",data);
        if (res.status != 200){
            if (res.status === 400) {
                throw new WrongLoginOrPasswordException();
            } else {
                throw new Error("что то произошло")
            }
        }
        return plainToClass(Token,res.data);
    }

    public async authAdmin(authRequest: AuthRequest) : Promise<Token>{
        const data = classToPlain(authRequest);
        const res = await this.httpClient.post("/admin/auth",data);
        if (res.status != 200){
            if (res.status === 400) {
                throw new WrongLoginOrPasswordException();
            } else {
                throw new Error("что то произошло")
            }
        }
        return plainToClass(Token,res.data);
    }

    public async exitUser(): Promise<Boolean>{
        const res = await this.httpClient.delete("/auth");
        return res.status === 200;
    }

    public mockGetAccount(accessToken: string | null): object|null{
        const res = this.mockAccounts.filter(account => account.accessToken === accessToken);
        return (res.length>0) ? res[0] : null;
    }

    public async getAccount(){
        const res = await this.httpClient.get("/user");
        console.log(res)
        if (res.status != 200){
            if (res.status == 400){

                // @ts-ignore
                const user: User = plainToClass(User,res.data);

                throw new NeedMoreInformationException(plainToClass(SchoolClass,user.schoolClass),user);
            }
        }


        return plainToClass(User,res.data);
    }

    public async getAccountAdmin(){
        const res = await this.httpClient.get("/admin");
        return res.data;
    }


    public async getSchoolById(id: number): Promise<School>{
        const res = await this.httpClient.get(`/account/${id}`);

        //@ts-ignore
        return plainToClass(School,res.data);
    }

    public async updateMyUserData(user: User): Promise<boolean>{
        const data = classToPlain(user);
        const res = await this.httpClient.put("/user",data);
        return (res.status === 204);
    }

    public async updateMyProfileData(user: User): Promise<boolean>{
        const data = classToPlain(user);
        const res = await this.httpClient.put("/user/profile",data);
        return (res.status === 204);
    }

    public async updateMyUserDataNew(dataObject: any): Promise<boolean>{
        const res = await this.httpClient.put("/user",dataObject);
        return (res.status === 204);
    }

    public async generateUsers(eduObjectId: number,schoolClassId : number, count : number){
        const res = await this.httpClient.post(`education/${eduObjectId}/class/${schoolClassId}/parents/generate/${count}`);

        return (res.status === 200)
    }

    public async getAllUsersInClass(eduObjectId : number, schoolClassId : number){
        const res = await this.httpClient.get(`education/${eduObjectId}/class/${schoolClassId}/parents`)

        return res.data
    }

    public async generatePdfUsers(availableUsers: any, unavailableUsers : any){

        const res = await this.httpClient.post('parents/generate',{availableUsers: availableUsers,unavailableUsers: unavailableUsers},{responseType: 'arraybuffer'});

        return res.data
    }

    public async deleteAvailableUsers(eduObjectId : number, schoolClassId : number){
        const res = await this.httpClient.delete(`education/${eduObjectId}/class/${schoolClassId}/parents`);

        if (res.status === 200){
            return true
        } else {
            return false;
        }
    }

    public async checkLoginExist(login: string): Promise<boolean>{
        const res = await this.httpClient.get(`user/${login}`)

        return (res.status !== 204)
    }


    public async getTeachers(educationObjectId : number){
        const res = await this.httpClient.get(`teachers/school/${educationObjectId}`)

        return res.data
    }

    public async createTeacher(educationObjectId : number,schoolClassId: number,data : any){
        const res = await this.httpClient.post(`teacher/school/${educationObjectId}/class`,data);

        return (res.status === 201);
    }


    public async editTeacher(teacherId : number,data : any){
        const res = await this.httpClient.put(`teacher/${teacherId}`,data);

        return (res.status === 204);
    }

    public async unionAccounts(data : any){
        const res = await this.httpClient.post(`user/union`,data)

        return res.status
    }

    public async getTariffs(){
        const res = await this.httpClient.get(`tariffs`)

        return res.data
    }

    public async startSecurityCode(){
        const res = await this.httpClient.post(`trust`)

        if (res.status === 200){
            return res.data;
        } else {
            return false
        }
    }

    public async validateSecurityCode(code : number){
        const res = await this.httpClient.put(`trust/${code}`);

        return res.status === 200
    }

    public async sendUntrustedSmsCode(){
        const res = await this.httpClient.post(`trust/sms`);

        if (res.status === 200){
            return res.data;
        } else {
            return false
        }
    }

}
