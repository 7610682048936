import {BaseGateway} from "@/gateway/BaseGateway";
import {ActiveCard} from "@/entity/ActiveCard";
import {plainToClass} from "class-transformer";
import {PaymentResponse} from "@/entity/PaymentResponse";

export class PaymentGateway extends BaseGateway{

    async enableRecurrent(){
        const res = await this.httpClient.post(`payment/recurrent`,{});
    }

    async disableRecurrent(){
        const res = await this.httpClient.delete(`payment/recurrent`,{});
    }

    async pay(isEnableRecurrent: boolean,tariffId: number, periodId: number) : Promise<PaymentResponse>{
        const data = {isEnableRecurrent: isEnableRecurrent,tariffId: tariffId, periodId: periodId};
        const res = await this.httpClient.post(`payment/recurring`,data);

        return plainToClass(PaymentResponse,res.data);
    }

    async downloadInvoice(id: number) : Promise<any>{
        return this.httpClient({
            url: `invoice/${id}`,
            method: 'GET',
            responseType: 'blob' // important
        })
    }

    async deleteActiveCard(activeCard: ActiveCard){
        //@ts-ignore
        const res = await this.httpClient.delete(`card/${activeCard.id}`,{})
    }
}