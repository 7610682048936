import {BaseException} from "@/entity/exception/BaseException";
import {SchoolClass} from "@/entity/SchoolClass";

export class NeedMoreInformationException extends BaseException{

    private readonly _schoolClass: SchoolClass;
    private readonly _profile: any;

    constructor(schoolClass: SchoolClass, profile?: any) {
        super("need more data","Нужно больше данных");
        this._schoolClass = schoolClass;
        this._profile = profile
    }


    get schoolClass(): SchoolClass {
        return this._schoolClass;
    }

    get profile() : any{
        return this._profile;
    }
}