import {Expose} from "class-transformer";

export class ActiveCard {
    @Expose({name : "id"})
    private id!: number;
    @Expose({name : "cardType"})
    private cardType!:string;
    @Expose({name : "cardExpDate"})
    private cardExpDate!:string;
    @Expose({name : "cardLastFour"})
    private cardLastFour!:number;
    @Expose({name : "isEnableRecurrent"})
    private isEnableRecurrent!:boolean;


    constructor(id: number, cardType: string, cardExpDate: string, cardLastFour: number, isEnableRecurrent: boolean) {
        this.id = id;
        this.cardType = cardType;
        this.cardExpDate = cardExpDate;
        this.cardLastFour = cardLastFour;
        this.isEnableRecurrent = isEnableRecurrent;
    }

    public isVisa(){
        return this.cardType == "Visa";
    }

    public isMasterCard(){
        return this.cardType == "MasterCard";
    }

    public isMir(){
        return this.cardType == "МИР";
    }

    public isMaestro(){
        return this.cardType == "Maestro"
    }
}
