import {Expose} from "class-transformer";
import moment from "moment";

export class Schedule {
    @Expose({name:"id"})
    private _id!: number;
    @Expose({name:"dayName"})
    private _dayName!: string;
    @Expose({name:"uri"})
    private _uri!: string;
    @Expose({name:"name"})
    private _name!: string;
    @Expose({name:"availableStatus"})
    private _availableStatus!: number;
    @Expose({name:"begin"})
    private _timeBegin: any;
    @Expose({name:"end"})
    private _timeEnd: any;


    constructor(id: number, dayName: string, uri: string, lessonName: string, availableStatus: number) {
        this._id = id;
        this._dayName = dayName;
        this._uri = uri;
        this._name = lessonName;
        this._availableStatus = availableStatus;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get dayName(): string {
        return this._dayName;
    }

    set dayName(value: string) {
        this._dayName = value;
    }

    get uri(): string {
        return this._uri;
    }

    set uri(value: string) {
        this._uri = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }


    get availableStatus(): number {
        return this._availableStatus;
    }

    set availableStatus(value: number) {
        this._availableStatus = value;
    }

    get isWork(): boolean{
        return this.availableStatus === Schedule.STATUS_BEGIN;
    }


    get timeBegin(): any {
        return moment(this._timeBegin.date).toDate();
    }

    set timeBegin(value: any) {
        this._timeBegin = value;
    }

    get timeEnd(): any {
        return moment(this._timeEnd.date).toDate();
    }

    set timeEnd(value: any) {
        this._timeEnd = value;
    }

    static get STATUS_ENDED(){
        return 0;
    }

    static get STATUS_BEGIN(){
        return 1;
    }

    static get STATUS_NOT_BEGIN(){
        return 2;
    }
}
