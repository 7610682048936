






import { Component, Vue } from 'vue-property-decorator';
import Loader from "@/components/loader/loader.vue";


@Component({
  components: {Loader}
})
export default class App extends Vue {}
