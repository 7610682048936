import {SchoolClass} from "@/entity/SchoolClass";
import {Expose, Type} from "class-transformer";

export class Invoice {
    @Expose({name: "id"})
    public id!: number;
    @Expose({name: "status"})
    public status!: boolean;
    @Expose({name: "class"})
    @Type(() => SchoolClass)
    public class!: SchoolClass;
    @Expose({name: "date"})
    public date!: string;
    @Expose({name: "price"})
    public price!: number;
}