
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import "reflect-metadata";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'
//@ts-ignore
import { VueWindowSizePlugin } from 'vue-window-size/option-api'


import Vue2TouchEvents from 'vue2-touch-events'



Vue.config.productionTip = false

Vue.use(VueWindowSizePlugin)
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })
Vue.use(Vue2TouchEvents)


function enableChatra () {
  (function (d, w, c) {
    // @ts-ignore
    w.ChatraID = 'ZiSFoNJ4oyNbqi4TC'
    var s = d.createElement('script')
    // @ts-ignore
    w[c] = w[c] || function () {
      // @ts-ignore
      (w[c].q = w[c].q || []).push(arguments)
    }
    s.async = true
    s.src = 'https://call.chatra.io/chatra.js'
    if (d.head) d.head.appendChild(s)
  })(document, window, 'Chatra')

  // @ts-ignore
  window.ChatraSetup = {
  }
}

enableChatra()
export const vueApp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
