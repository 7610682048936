import {Expose} from "class-transformer";

export class SchoolClass{
    @Expose({name : "id"})
    private _id!: number;
    @Expose({name : "fullName"})
    private _fullName!: string;


    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get fullName(): string {
        return this._fullName;
    }

    set fullName(value: string) {
        this._fullName = value;
    }
}