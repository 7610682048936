import {Expose} from "class-transformer";

export class PaymentResponse {

    @Expose({name: "success"})
    private _success!:boolean;
    @Expose({name: "cardHolderMessage"})
    private _cardHolderMessage!:string;


    get success(): boolean {
        return this._success;
    }

    set success(value: boolean) {
        this._success = value;
    }

    get cardHolderMessage(): string {
        return this._cardHolderMessage;
    }

    set cardHolderMessage(value: string) {
        this._cardHolderMessage = value;
    }
}