import {BaseGateway} from "@/gateway/BaseGateway";

export class ShareGateway extends BaseGateway {
  async getShareByToken (token: string) {
    const res = await this.httpClient.get(`share/${token}`)

    return res.data
  }

  async createShare (cameraId : number,settingId: number) {
    const res = await this.httpClient.post(`share/camera/${cameraId}/setting/${settingId}`)

    return res.data
  }
}
