import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'profile',
  //   component: () => import('../views/Profile.vue')
  // },
  {
    path: '/',
    name: 'ParentMain',
    component: () => import('../views/Parent/Main.vue'),
    children: [
      {
        path: '',
        name: 'ParentDashboard',
        component: () => import('../views/Parent/DashboardViewNew.vue'),
        meta: {
          title: 'Главная',
        }
      },
      {
        path: 'main',
        name: 'ParentDashboard',
        component: () => import('../views/Parent/DashboardViewNew.vue'),
        meta: {
          title: 'Главная',
        }
      },
      // {
      //   path: 'settings',
      //   name: 'ParentProfile',
      //   component: () => import('../views/Parent/ProfileView.vue'),
      //   meta: {
      //     title: 'Настройки',
      //   },
      //   childern: [
      //
      //   ]
      // },
      {
        path: '/settings',
        name: 'SettingsMainView',
        component: () => import('../views/Parent/Settings/SettingsMainView.vue'),
        children: [
          {
            path: '/',
            name: 'SettingsMainListView',
            component: () => import('../views/Parent/Settings/SettingsMainListView.vue'),
            meta: {
              title: 'Настройки',
            }
          },
          {
            path: 'classes',
            name: 'SettingsClassesView',
            component: () => import('../views/Parent/Settings/SettingsClassesView.vue'),
            meta: {
              title: 'Родительский кабинет',
            },
            children: [
              {
                path: 'add',
                name: 'SettingsClassesAddView',
                component: () => import('../views/Parent/Settings/SettingsClassesAddView.vue'),
                meta: {
                  title: 'Родительский кабинет',
                },
              }
            ]
          },
          {
            path: 'profile',
            name: 'SettingsProfileView',
            component: () => import('../views/Parent/Settings/SettingsProfileView.vue'),
            meta: {
              title: 'Настройки профиля',
            }
          },
          {
            path: 'faq',
            name: 'SettingsFaqView',
            component: () => import('../views/Parent/Settings/SettingsFaqView.vue'),
            meta: {
              title: 'FAQ',
            }
          },
          {
            path: '/archive/class',
            name: 'ArchiveClassList',
            component: () => import("../views/Parent/ArchiveLessonView/ArchiveListClassesView.vue"),
            meta: {
              title: "Выберите Класс"
            },
          },
          {
            path: '/archive/class/:classId',
            name: 'ArchiveListView',
            component: () => import("../views/Parent/ArchiveLessonView/ArchiveListView.vue"),
            meta: {
              title: "Смотреть запись"
            }
          },
          {
            path: '/archive/desktop',
            name: 'ArchiveDesktopListView',
            component: () => import("../views/Parent/ArchiveLessonView/ArchiveDesktopListView.vue"),
            meta: {
              title: "Смотреть запись"
            }
          },
          {
            path: 'payment',
            name: 'ParentPay',
            component: () => import('../views/Parent/PayViewNew.vue'),
            meta: {
              title: 'Управление тарифом',
            }
          },
        ]
      },
    ]
  },
  {
    path: '/congratulation',
    name: 'CongratulationView',
    component: () => import("../views/Parent/CongratulationView.vue"),
    meta: {
      title: 'Поздравляем'
    }
  },
  {
    path: '/interview',
    name: 'InterviewView',
    component: () => import("../views/Parent/InterviewView.vue"),
    meta: {
      title: 'Карточка родителя',
    }
  },
  {
    path: '/security',
    name: 'SecurityView',
    component: () => import("../views/Parent/Code/CodeView.vue"),
    meta: {
      title: 'Система безопасности',
    }
  },
  {
    path: '/auth',
    name: 'NewAuth',
    component: () => import('../views/Auth/AuthView.vue'),
    meta: {
      title: 'Авторизация',
    }
  },
  {
    path: '/share/:token',
    name: 'EyeShareView',
    meta: {
      title: 'EyeShare'
    },
    component: () => import('../views/Share/EyeShareView.vue')
  },
  {
    path: '/archive/:id',
    name: 'ArchivePleerView',
    component: () => import("../views/Parent/ArchiveLessonView/ArchivePleerView.vue"),
    meta: {
      title: "Архив"
    }
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  //@ts-ignore
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  // @ts-ignore
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
      // Add the meta tags to the document head.
      //@ts-ignore
      .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
