import {Expose} from "class-transformer";

export default class Token {
    //@ts-ignore
    private _accessToken: string;
    //@ts-ignore
    private _refreshToken: string;

    @Expose()
    get accessToken(): string {
        return this._accessToken;
    }

    set accessToken(value: string) {
        this._accessToken = value;
    }
    
    @Expose()
    get refreshToken(): string {
        return this._refreshToken;
    }

    set refreshToken(value: string) {
        this._refreshToken = value;
    }
}