import {Expose, Type} from "class-transformer";
import {Camera} from "@/entity/Camera";
import {Schedule} from "@/entity/Schedule";

export class School {
    @Expose({name:"id"})
    public id!: number;
    @Expose({name:"name"})
    public name!: string;
    @Expose({name:"address"})
    public address!: string;
    @Expose({name:"avatar"})
    public avatar!: string;
    @Expose({name:"phone"})
    public phone! :string;
    @Expose({name:"shared"})
    @Type(() => Camera)
    public shared: Camera[] = [];
    @Expose({name:"schedules"})
    @Type(() => Schedule)
    public schedules: Schedule[] = [];
}