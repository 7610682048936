import {AxiosInstance} from "axios";
import BaseClient from "@/BaseClient";


export class BaseGateway {
    protected httpClient : AxiosInstance;


    constructor() {
        this.httpClient = BaseClient.getHttpClient();
    }

}